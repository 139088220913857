/* You can add global styles to this file, and also import other style files


Font Family --

    font-family: 'Manrope', sans-serif;

    font-family: 'Roboto', sans-serif;

    font-family: 'Georama', sans-serif;

*/

@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import "~bootstrap/dist/css/bootstrap.css";
@import "~angular-notifier/styles";
@import "~@ng-select/ng-select/themes/default.theme.css";
// @import "~@ng-select/ng-select/themes/material.theme.css";

// ngx-datatable scss
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/themes/dark.scss';
@import '~@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';

/* Normalize CSS */

.ngx-datatable.material {
    .datatable-header {
      .datatable-header-cell {
        // your styles here
        color: #404040;
        font-size: 12px !important;
        background-color: #F4FAF8 !important;
        // text-align: center;
      }
    }
  }

* {
    margin: 0px;
    padding: 0px;
    outline: 0px;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 100%;
    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    scroll-behavior: smooth;
}

body {
    color: #666;
    font-family: "Georama", sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5;
    overflow-x: hidden;
}

.clear {
    clear: both;
}

::-moz-placeholder {
    opacity: 1;
}

input,
textarea,
select {
    font-family: "Georama", sans-serif;
}

.container {
    position: relative;
    width: 90%;
    margin: auto;
}

img {
    max-width: 100%;
    height: auto
}

a {
    color: #1BB97A;
    text-decoration: none;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

a:hover {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    color: #f57310;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
label,
input,
textarea,
img,
submit,
button,
a {
    margin: 0px;
    padding: 0px;
    outline: none;
    list-style: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
// small,
strike,
strong,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    border: 0;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

svg:not(:root) {
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    padding-bottom: 10px;
    color: #333;
    line-height: 1.22;
}

h1 {
    font-weight: 600;
    font-size:44px;
    line-height: 1.2;
    margin-bottom: 1rem;
}

h2 {
    font-weight: 600;
    font-size: 44px;
    line-height: 1.2;
    margin-bottom: 1rem;
}
h2 strong,h1 strong{
    background-color: #84FFCE;
    font-weight: 600;
    padding: 0 5px;
}

h3 {
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 1.2;
    margin-bottom: 1rem;
}

h4 {
    font-size: 1.8rem;
    line-height: 1.2;
    margin-bottom: 0.8rem;
}

h5 {
    font-size: 1.4rem;
    line-height: 1.4;
    margin-bottom: 0.8rem;
}

h6 {
    font-size: 1.2rem;
    line-height: 1.4;
    margin-bottom: 0.8rem;
}

button {
    border: 0;
    background: none;
    cursor: pointer;
}

p {
    color: #666;
    font-size: 1.25rem;
    line-height: 1.4;
    margin-bottom: 0.8rem;
}

[class*="row"] :last-child {
    margin-bottom: 0;
}

.bg_light {
    background: #f6f6f7;
}

.form-control {
    font-size: 14px;
    background: #fff;
}

.form-control:focus {
    box-shadow: none;
}

.btn {
    display: inline-block;
    color: #fff!important;
    background: #1BB97A;
    font-size: 1rem;
    line-height: 1.6;
    font-weight: 400;
    padding: 10px 20px;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    min-width: 120px;
    text-align: center;
    border: 1px solid #1BB97A;
    font-family: 'Georama', sans-serif;
    text-transform: uppercase;
    border-radius: 4px;    
    min-width: 210px;
    text-align: center;
}

.btn:hover,
a.btn:hover {
    transition: all 0.4s ease-in-out;
    background: #84FFCE;
    color: #333!important;
    text-decoration: none;
    border-color: #84FFCE;
}
.btn-border {
    display: inline-block;
    color: #1BB97A;
    background: #fff;
    font-size: 1rem;
    line-height: 1.6;
    font-weight: 400;
    padding: 10px 20px;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    min-width: 120px;
    text-align: center;
    border: 1px solid #1BB97A;
    font-family: 'Georama', sans-serif;
    text-transform: uppercase;
    border-radius: 4px;
    min-width: 210px;
    text-align: center;
}

.btn-border:hover {
    transition: all 0.4s ease-in-out;
    background: #84FFCE;
    color: #333;
    text-decoration: none;
    border-color: #84FFCE;
}

.btn-secondary {
    color: #141112!important;
    background: rgba(0, 0, 0, 0);
    border-width: 1px;
    border-color: #141112;
    font-size: 14px;
    font-family: 'Georama', sans-serif;
}

.btn-secondary:hover {
    color: #fff!important;
    background: #141112;
    border-color: #141112;
}

.btn-light {
    color: #999999!important;
    background: rgba(0, 0, 0, 0);
    border-width: 1px;
    border-color: #999999;
    font-size: 14px;
}

.btn-light:hover {
    color: #fff!important;
    background: #141112;
    border-color: #141112;
}

.btn:focus,
.btn:active {
    box-shadow: none;
}

.btn-link {
    color: #4cc7e9;
    border: 0;
    font-size: 14px;
    font-weight: 400;
    background: none;
    padding: 0;
}

.pointer {
    cursor: pointer;
}

.btn-link:hover {
    text-decoration: underline;
}

textarea {
    resize: none
}

::-webkit-input-placeholder {
    color: #707070;
    opacity: 1;
}

::-moz-placeholder {
    color: #707070;
    opacity: 1;
}

.logo,
select:hover,
textarea:hover,
input:hover,
button:hover,
select:focus,
textarea:focus,
input:focus,
button:focus,
label:focus,
select:active,
textarea:active,
input:active,
button:active,
.btn:active {
    outline: 0px !important;
    // -webkit-appearance: none!important;
    box-shadow: none !important;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    // -webkit-appearance: none!important;
    margin: 0!important;
}

input[type="number"] {
    -moz-appearance: textfield!important;
}

input[type="number"]:hover,
input[type="number"]:focus {
    -moz-appearance: textfield!important;
}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
}

a:focus,
a:active,
a:hover {
    outline: 0px!important;
    box-shadow: none !important;
    -webkit-appearance: none!important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none!important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #dc3545!important;
}

.owl-theme .owl-nav {
    text-align: left;
    margin: 25px 0 0;
}
.owl-theme .owl-nav [class*="owl-"]{
    padding: 0;
    margin: 0;
    background: none!important;
}
.owl-theme .owl-nav .owl-prev {
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
}
.owl-theme .owl-nav .owl-next {
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
}
.owl-theme .owl-nav [class*="owl-"]::before {
    content: "";
    position: relative;
    background-image: url(assets/images/prev-arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 34px;
    height: 34px;
    border-radius: 20px;
    display: block;
}

.owl-theme .owl-nav .owl-next:before {
    background-image: url(assets/images/next-arrow.svg);
}

section {
    clear: both;
}

section .btn, section .btn-border {
    margin-top: 20px;
}

.form-input-error {
    border: 1px solid #dc3545 !important;
}

.notifier__notification p {
    color: white;
}

.ngb-dp-arrow .ngb-dp-arrow-btn {
    background-color: transparent !important;
    border: none !important;
    margin: 0 .5rem !important;
    padding: 0 .25rem !important;
    z-index: 1 !important;
    font-weight: 400 !important;
    color: #007bff !important;
    text-decoration: none !important;
    min-width: auto !important;
    box-shadow: none !important;
}

.ngb-dp-arrow .ngb-dp-arrow-btn :hover {
    color: #0056b3 !important;
    text-decoration: underline !important;
    background: transparent !important;
    margin: 0 .5rem !important;
    padding: 0 .25rem !important;
    z-index: 1 !important;
    font-weight: 400 !important;
    min-width: auto !important;
    box-shadow: none !important;
}


/* Normalize CSS */

.authForm .ng-select-container {
    min-height: 50px!important;
    border-radius: 10px;
}

.authForm .ng-select-container .ng-placeholder {
    top: 13px!important;
    padding-left: 10px!important;
}

.authForm .ng-value {
    background-color: #3BB95B!important;
    border-radius: 20px!important;
    overflow: hidden!important;
    color: #fff!important;
}

.authForm .ng-value .ng-value-icon.left {
    border-right: 1px solid #1FAB43!important;
}

.ng-select.form-input-error {
    border: 0 !important;
}

.ng-select.form-input-error .ng-select-container {
    border-color: #dc3545!important;
}

.srchField .ng-select:not(.ng-invalid) .ng-value-label {
    padding: 0 10px!important;
}

.authForm .ng-value .ng-value-label {
    padding: 2px 12px 2px 7px!important;
}

.authForm .ng-value .ng-value-icon {
    padding: 2px 7px!important;
}

body{
    padding-top: 92px;
}
.logoSlider .owl-prev{
    left: -40px!important;
} 
.logoSlider .owl-next{
    right: -40px!important;
} 
.logoSlider .owl-nav{ 
    display: none!important;
}

.modal-dialog{
    max-width: 400px;
}

.ngx-datatable.material {
	background: #fff;
	padding: 15px !important;
	border-radius: 6px !important;
	box-shadow: 0 0 10px rgba(0,0,0,0.1)!important;
}
.ngx-datatable.material .datatable-header{
    border: 0!important;
    height: auto !important;
}
.ngx-datatable.material .datatable-header .datatable-header-cell {
	padding: 10px !important;
	color: #333!important;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell{
    padding: 10px !important;
	color: #333!important;
    background: #fff !important;
    border-top: 1px solid #e5e5e5 !important;
}
.ngx-datatable.material .datatable-body{
    overflow: hidden!important;
}
.ngx-datatable .datatable-body .datatable-row-wrapper:nth-child(1) .datatable-body-row .datatable-body-cell{
    border-top: 0!important;
}


/* Responsive Start */

@media(max-width:1200px) {
   .dashboardpage .container, .dashboardpage .container-sm, .dashboardpage .container-md {
        max-width: 100%;
    }
    h1 {
        font-size: 2.6rem;
        line-height: 1.2;
    }
    h2 {
        font-size: 2rem;
        line-height: 1.2;
    }
    h3 {
        font-size: 1.6rem;
    }
    h4 {
        font-size: 1.4rem;
    }
    h5 {
        font-size: 1.2rem;
    }
    h6 {
        font-size: 1rem;
    }
    .btn {
        padding: 0.6em 1em;
        min-width: 130px;
    }
    body,
    p {
        font-size: 1rem;
        line-height: 1.6;
    }
}

@media(max-width:979px) {
    body{
        padding-top: 68px;
    }
    .btn, .btn-border{
        min-width: 190px;
        padding: 8px 15px;
        font-size: 15px;
    }
}
@media(max-width:767px) {
    h1 {
        font-size: 1.85rem;
        line-height: 1.28;
    }
    h2 {
        font-size: 1.65rem;
        line-height: 1.28;
        margin-bottom: 5px;
    }
    h3 {
        font-size: 1.3rem;
    }
    h4 {
        font-size: 1.2rem;
    }
    h5 {
        font-size: 1.1rem;
    }
    .btn {
        padding: 0.5em 1em;
        min-width: 120px;
    }
    .logoSlider .owl-prev{
        left: -15px!important;
    } 
    .logoSlider .owl-next{
        right: -15px!important;
    } 
    .logoSlider .owl-nav{ 
        margin: 0!important;
    }
    .owl-theme .owl-nav [class*="owl-"]::before{
        width: 28px;
        height: 28px;
        background-size: 100%;
    }
    .dashboardpage {
        min-height: initial!important;
    }
}